@import "src/style/variables.scss";

$headerHeight: 64px;

.SystemStatusesPanel {
    .header {
        padding: $gutterSmall;

        .pageHeader {
            margin-bottom: 0px !important;
        }
    }

    .categories {
        overflow-y: auto;
    }

    .SpintrStaticLinksCallout-header {
        padding-top: 0;
    }

    .SpintrStaticLinksCallout-header-icons {
        top: 0;

        .SpintrStaticLinksCallout-header-icon {
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
        }
    }

    .ConversationsPanelHeader {
        height: $headerHeight - $gutterSmall * 2;
        padding: $gutterMedium;
        padding-top: 0;

        .ConversationsPanel-header {
            height: $headerHeight - $gutterSmall * 2;
            padding: $gutterMedium;
            padding-top: 0;
        }

        .searchBox {
            background-color: color(light-grey);
        }
    }

    .ConversationItem {
        align-items: center;
        box-sizing: border-box;
        gap: $gutterSmall;
        max-width: 100%;
        width: 100%;

        .text {
            overflow: hidden;
        }

        .lower {
            margin-top: 2px;
        }

        .actionmenu-component {
            flex: 0 0 24px;
        }

        .action-menu {
            display: flex;
            align-self: center;
            margin-left: $gutterTiny;
        }

        .SystemStatusResourceInfo {
            flex: 1 1 calc(100% - 24px - $gutterSmall);
            max-width: calc(100% - 24px - $gutterSmall);

            .ms-Persona-details {
                padding-right: 0;

                .title-row {
                    > .label-component {
                        flex: 1 1 auto;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
    
                    > .SystemStatusTypePill {
                        flex: 0 0 auto;
                    }
                }
            }
        }
    }

    .segment {
        margin-bottom: $gutterMedium;

        .subline {
            margin-left: $gutterMedium;
            margin-bottom: $gutterXSmall;
        }
    }
}

.system-status-resource-modal {
    .ms-Dialog-main {
        width: 600px;
    }

    .create-button {
        border-radius: 50%;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            opacity: 0.5;
        }
    }

    .faq-item {
        border: 1px solid color(borderColor);
        border-radius: $borderRadius;
        padding: 8px;

        .question-control {
            position: relative;
            padding-top: 1px;

            .remove-button {
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }
}
