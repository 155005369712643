@import "src/style/variables";

.AcademyCourseList {
    margin-bottom: $gutterXXLarge;

    &:last-child {
        margin-bottom: 0px;
    }

    > .label-component {
        margin-bottom: $gutterMedium;
    }

    .courses {
        display: flex;
        flex-direction: row;
        gap: $gutterMedium;
        flex-wrap: wrap;
    }
}
