@import "src/common.scss";

.Text {
    /* Defaults */
    color: $spintrContentNormal;
    font-weight: $fwRegular;
    line-height: $lhHigher;

    /* Spacing */
    &.ls-dense {
        letter-spacing: $lsDense;
    }
    
    &.ls-normal {
        letter-spacing: $lsNormal;
    }
    
    &.ls-wide {
        letter-spacing: $lsWide;
    }

    /* Line Heights */
    &.lh-narrow {
        line-height: $lhNarrow;
    }
    
    &.lh-low {
        line-height: $lhLow;
    }
    
    &.lh-normal {
        line-height: $lhNormal;
    }
    
    &.lh-high {
        line-height: $lhHigh;
    }
    
    &.lh-higher {
        line-height: $lhHigher;
    }

    /* Sizes */
    &.fs-caption {
        font-size: $fsCaption;
    }

    &.fs-bodySmall {
        font-size: $fsBodySmall;
    }

    &.fs-bodyMedium {
        font-size: $fsBodyMedium;
    }

    &.fs-bodyLarge {
        font-size: $fsBodyLarge;
    }

    &.fs-headingSmall {
        font-size: $fsHeadingSmall;
    }

    &.fs-headingLarge {
        font-size: $fsHeadingLarge;
    }

    /* Weights */
    &.fw-regular {
        font-weight: $fwRegular;
    }

    &.fw-medium {
        font-weight: $fwMedium;
    }

    &.fw-semiBold {
        font-weight: $fwSemibold;
    }

    /* Colors */
    @each $name, $color in $spintrColors {
        &.fc-#{"" + $name} {
            color: $color;
        }
    }
}
