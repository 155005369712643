@import "src/style/variables";

.fs-big {
    font-family: "Eloquia", sans-serif;
    font-size: 28px !important;
    font-weight: 700 !important;
    // line-height: 57px !important;
}

.fs-h1, h1 {
    font-family: "Eloquia", sans-serif;
    font-size: 26px;
    font-weight: 400;
    line-height: 1.500em;
}

.fs-h2, h2 {
    font-family: "Eloquia", sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 1.5em;
}

.fs-h3, h3 {
    font-family: "Eloquia", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5em;
}

.fs-h4, h4 {
    font-family: "Eloquia", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.250em;
}

.fs-h5, h5 {
    font-family: "Eloquia", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.250em;
}

.fs-h6, h6 {
    font-family: "Eloquia", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.250em;
}

.fs-h7{
    font-family: "Eloquia", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.250em;   
}

.fs-body-1 {
    font-family: "Eloquia", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5em;
}

.fs-body-2 {
    font-family: "Eloquia", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5em;
}

.fs-body-3 {
    font-family: "Eloquia", sans-serif;
    font-size: 10px;
    font-weight: 400;
    line-height: 1.250em;
}

.fs-body-4 {
    font-family: "Eloquia", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5em;
}

.fs-body-5 {
    font-family: "Eloquia", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5em;
}

.fs-small-1 {
    font-family: "Eloquia", sans-serif;
    font-size: 10px;
    font-weight: 550;
    line-height: 1em;
}

.fs-small-2 {
    font-family: "Eloquia", sans-serif;
    font-size: 10px;
    font-weight: 550;
    line-height: 11px;
}

.fw-extra-bold {
    font-weight: 900;
}

.fw-bold {
    font-weight: 700;
}

.fw-semi-bold {
    font-weight: 600;
}

.fw-medium {
    font-weight: 550;
}

.fw-regular {
    font-weight: 400;
}

.fw-normal {
    font-weight: normal;
}

.fw-light {
    font-weight: 300;
}

.label-component {
    letter-spacing: -0.2px;

    &.uppercase {
        text-transform: uppercase;
    }

    &.subline-letter-spacing {
        letter-spacing: 1px;
    }

    &.centerText {
        text-align: center;
    }

    &.italic {
        font-style: italic;
    }

    &.underline {
        text-decoration: underline;
    }

    &.line-through {
        text-decoration: line-through;
    }
}

@each $color, $hex in $colors {
    .fc-#{"" + $color} {
        color: $hex;
    }
}
