@import "src/style/variables";

.pageHeaderImageWrapper {
    &:hover, &:focus {
        .pageHeaderImageDeleteOverlay {
            display: block !important;
        }
    }

    .pageHeaderImageDeleteOverlay {
        .image-action {
            background-color: rgba(0, 0, 0, 0.25);
            border-radius: $borderRadiusSharper;
            margin: 0px $gutterXSmall;

            &:hover {
                opacity: 0.75;
            }

            .label-component {
                margin-top: $gutterXSmall;
                margin-bottom: 0px;
            }
        }
    }
}
.pageHeaderImageWrapperCrop {
    .CropControls {
        display: flex;

        .ms-Slider {
            max-width: 300px;
            flex-grow: 1;
        }
    }
}

.with-blur {
    width: 100% !important;
    display: block;
    height: auto;
    width: 100%;
    overflow: hidden;
    position: relative;
    border-radius: $borderRadius;

    img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
}