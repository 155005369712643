/**
 * Header and footer sizes
 */
/**
 * Circle sizes
 */
/**
 * Z-Index Table
 */
/*
Transitions
*/
/* 
Responsive breakpoints:

Startpage:
 * 1509: Start scaling down social feed
 * 1439: Stop scaling social feed, start scaling information feed
 * 1280: Reduce sidebar width from spintrSidebarMaxWidth to spintrSidebarMinWidth
*/
/**
 * Header and footer sizes
 */
/**
 * Circle sizes
 */
/**
 * Z-Index Table
 */
/*
Transitions
*/
/* 
Responsive breakpoints:

Startpage:
 * 1509: Start scaling down social feed
 * 1439: Stop scaling social feed, start scaling information feed
 * 1280: Reduce sidebar width from spintrSidebarMaxWidth to spintrSidebarMinWidth
*/
.fs-big {
  font-family: "Eloquia", sans-serif;
  font-size: 28px !important;
  font-weight: 700 !important;
}

.fs-h1, h1 {
  font-family: "Eloquia", sans-serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 1.5em;
}

.fs-h2, h2 {
  font-family: "Eloquia", sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.5em;
}

.fs-h3, h3 {
  font-family: "Eloquia", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5em;
}

.fs-h4, h4 {
  font-family: "Eloquia", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25em;
}

.fs-h5, h5 {
  font-family: "Eloquia", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.25em;
}

.fs-h6, h6 {
  font-family: "Eloquia", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.25em;
}

.fs-h7 {
  font-family: "Eloquia", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.25em;
}

.fs-body-1 {
  font-family: "Eloquia", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
}

.fs-body-2 {
  font-family: "Eloquia", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5em;
}

.fs-body-3 {
  font-family: "Eloquia", sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.25em;
}

.fs-body-4 {
  font-family: "Eloquia", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
}

.fs-body-5 {
  font-family: "Eloquia", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5em;
}

.fs-small-1 {
  font-family: "Eloquia", sans-serif;
  font-size: 10px;
  font-weight: 550;
  line-height: 1em;
}

.fs-small-2 {
  font-family: "Eloquia", sans-serif;
  font-size: 10px;
  font-weight: 550;
  line-height: 11px;
}

.fw-extra-bold {
  font-weight: 900;
}

.fw-bold {
  font-weight: 700;
}

.fw-semi-bold {
  font-weight: 600;
}

.fw-medium, .ConversationItem.invite .GroupInvitationNotification .info {
  font-weight: 550;
}

.fw-regular {
  font-weight: 400;
}

.fw-normal {
  font-weight: normal;
}

.fw-light {
  font-weight: 300;
}

.label-component {
  letter-spacing: -0.2px;
}
.label-component.uppercase {
  text-transform: uppercase;
}
.label-component.subline-letter-spacing {
  letter-spacing: 1px;
}
.label-component.centerText {
  text-align: center;
}
.label-component.italic {
  font-style: italic;
}
.label-component.underline {
  text-decoration: underline;
}
.label-component.line-through {
  text-decoration: line-through;
}

.fc-black {
  color: #000;
}

.fc-dark-grey {
  color: #22234A;
}

.fc-mid-grey {
  color: #475267;
}

.fc-grey {
  color: #727E94;
}

.fc-dusk-grey {
  color: #d5d5d5;
}

.fc-light-grey {
  color: #F8F8FF;
}

.fc-light-blue {
  color: #787CDD;
}

.fc-red {
  color: #EB0014;
}

.fc-salmon {
  color: #FC5371;
}

.fc-smoke {
  color: #EAEAEA;
}

.fc-white {
  color: #FFF;
}

.fc-office365 {
  color: #D83B01;
}

.fc-green {
  color: #1F7F35;
}

.fc-spintrGreen {
  color: #00A578;
}

.fc-outline {
  color: #488dc8;
}

.fc-yellow {
  color: #ADA000;
}

.fc-orange {
  color: #FFAE2C;
}

.fc-darkOrange {
  color: #A86800;
}

.fc-disabled {
  color: #a19f9d;
}

.fc-visageGray {
  color: #091B3D;
}

.fc-visageGray2 {
  color: #363853;
}

.fc-visageGray3 {
  color: #6D7588;
}

.fc-visageGray6 {
  color: #F7F7F9;
}

.fc-neutralBlue {
  color: #2772F0;
}

.fc-borderColor {
  color: #F4F4F5;
}

.fc-borderColor2 {
  color: #F7F8FA;
}

.fc-bookmarkColor {
  color: #F8F9FF;
}

.fc-blueHighlight {
  color: #ECE9FE;
}

.fc-visageMidBlue {
  color: #787CDD;
}

.fc-visageMidBlueBackground {
  color: #F1F2FC;
}

.fc-visage2LightGray {
  color: #F4F4F5;
}

.fc-visage2LightGray2 {
  color: #ECF0F3;
}

.fc-spintrWhite {
  color: #FFFFFF;
}

.fc-spintrGrey {
  color: #727E94;
}

.fc-primaryContent {
  color: #4E4F6C;
}

.fc-backgroundColor {
  color: #F0F0F0;
}

.ConversationItem {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  -webkit-transition: background-color 250ms;
  -moz-transition: background-color 250ms;
  -o-transition: background-color 250ms;
  transition: background-color 250ms;
}
.ConversationItem .lower {
  line-height: 1.2em !important;
}
.ConversationItem.unstyled-button {
  cursor: pointer;
}
.ConversationItem.unstyled-button.active, .ConversationItem.unstyled-button:hover {
  background-color: #F4F4F5;
}
.ConversationItem.unstyled-button.active .text > .lower > .message, .ConversationItem.unstyled-button:hover .text > .lower > .message {
  color: #22234A;
}
.ConversationItem.invite {
  background-color: #F4F4F5;
}
.ConversationItem.invite .image {
  align-self: start;
}
.ConversationItem.invite .GroupInvitationNotification {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  margin-left: 12px;
}
.ConversationItem.invite .GroupInvitationNotification .info {
  font-size: 12px;
}
.ConversationItem.invite .GroupInvitationNotification .actions {
  display: flex;
  flex-direction: row;
  column-gap: 12px;
}
.ConversationItem .text {
  flex-grow: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  display: flex;
  width: 0;
  flex-direction: row;
}
.ConversationItem .text > div {
  display: flex;
  flex-direction: column;
  width: 0;
  flex-grow: 1;
}
.ConversationItem .text > div:first-child {
  width: calc(100% - 50px);
}
.ConversationItem .text > div:first-child .bottom {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.ConversationItem .text > div:last-child {
  width: 50px;
  align-items: flex-end;
}
.ConversationItem .text > div .top {
  height: 20px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ConversationItem .text > div .bottom {
  height: 24px;
  line-height: 24px;
  flex-direction: row;
  display: flex;
  align-items: center;
}
.ConversationItem .text .name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ConversationItem .text .message {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ConversationItem .text .message.group-view {
  width: 100% !important;
}
.ConversationItem .text .date {
  text-align: right;
  width: 50px;
  white-space: nowrap;
  height: 20px;
  line-height: 20px;
}
.ConversationItem .text .upper, .ConversationItem .text .lower {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 6px;
}
.ConversationItem .text .upper .right, .ConversationItem .text .lower .right {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 6px;
}
.ConversationItem .text .upper .right .action-menu, .ConversationItem .text .lower .right .action-menu {
  height: 24px;
  opacity: 0;
}
.ConversationItem .action-menu {
  opacity: 0;
  background-color: #FFF;
  height: 24px;
  width: 0px;
  border-radius: 24px;
}
.ConversationItem.unread .lower .message {
  color: #091B3D;
}
.ConversationItem:hover .action-menu {
  opacity: 1 !important;
  width: 24px;
  margin-left: 6px;
}