@import "src/style/variables.scss";

.SocialPostShare {
    margin-top: $gutterMedium;
}

.shared-post {
    margin-top: $gutterSmall;

    .SocialPostContainer{
        margin-bottom: 0px;
        box-shadow: none;
        background-color: color(bookmarkColor);

        .StandardActionMenu {
            display: none;
        }
    }

    .post-wrapper {
        margin-bottom: 0;

        .SocialPostContainer {
            .SocialPost {
                border-radius: $borderRadius;
                box-shadow: 0;
                margin: 0;
                padding: 0;
            }
        }
    }
}

.shared-content-post {
    padding: $gutterMedium;
    border: 1px solid color("borderColor");
    border-radius: $borderRadius;
    position: relative;

    a {
        color: color(dark-grey);
    }

    img {
        max-width: 100%;
        border-radius: $borderRadius;
    }

    .shared-content-post-title {
        margin: 0;
        margin-bottom: $gutterTiny;
    }
}