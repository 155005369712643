@import "src/style/variables.scss";

.file-picker-popup {
    width: calc(100vw - #{$gutterMedium * 2});
    max-width: 1150px;

    .header {
        padding: $gutterMedium;
        position: relative;

        .close-button {
            position: absolute;
            top: 18px;
            right: 18px;
        }
    }

    .bar {
        padding: $gutterXSmall $gutterMedium;
        border-top: 1px solid color(dusk-grey);
        border-bottom: 1px solid color(dusk-grey);
        min-height: 51px;

        .source {
            width: 235px;
            display: flex;
            align-items: center;
        }

        .save-button {
            display: flex;
            align-items: center;
        }
    }

    .sources {
        border-right: 1px solid color(dusk-grey);
        width: 250px;
        height: 550px;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .content {
        width: calc(100% - 250px);
        position: relative;
        height: 550px;

        .drop-zone {
            height: 550px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .file-picker-progress-bar-wrapper {
            margin: $gutterMedium;
        }

        .ms-CommandBar {
            margin-bottom: 0;
        }

        .hasNotSearched {
            .spintr-list {
                .ms-Viewport, .textNoTodo {
                    display: none;
                }
            }
        }
    }

    .footer {
        border-top: 1px solid color(dusk-grey);
        padding: $gutterMedium;
        text-align: center;
    }

    .submenu-component {
        .ms-Nav-groupContent {
            margin-bottom: 0 !important;
        }
    }

    .ms-Breadcrumb {
        margin: 0 !important;
    }

    .ms-DetailsList {
        .ms-DetailsHeader {
            padding-top: 0 !important;
        }

        .ms-DetailsRow {
            .preview-image,
            .icon-wrapper {
                width: 30px;
                height: 30px;
                margin: 0 $gutterXSmall;
                vertical-align: middle;
            }

            .preview-image {
                display: inline-block;

                background-position: center;
                background-size: center;
                vertical-align: middle;
            }

            .icon-wrapper {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                background-color: color("dusk-grey");

                > i {
                    font-size: 15px;
                }
            }

            a {
                vertical-align: middle;
            }
        }
    }
}

.isSmallViewMode {
    .file-picker-popup {
        .sources {
            width: 100%;
            height: initial;

            .submenu-component {
                width: initial;
            }
        }

        .content {
            width: 100%;
            height: 200px;

            .drop-zone {
                height: initial;
                padding: 64px;
            }
        }
    }
}
