@import "src/style/variables.scss";

.CalypsoSupportBanner {
    padding: $gutterXXLarge;
    padding-bottom: 0px;

    > .CalypsoSupportBanner-inner {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: color(visageMidBlue);
        padding: $gutterSmall $gutterMedium;
        border-radius: $borderRadius;
        gap: $gutterSmall;

        .main {
            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: $gutterSmall;
            flex-grow: 1;

            .icon-wrapper {
                background-color: #888ce1;
                border-radius: 40px;
                height: 40px;
                width: 40px;
                display: flex;
                align-items: center;
                align-content: center;
                justify-content: center;

                > * {
                    padding: $gutterSmall;

                    > div {
                        height: 30px;
                        width: 30px;
                    }

                    svg {
                        height: 30px;
                        width: 30px;
                    }
                }
            }

            .content {
                flex-grow: 1;
            }
        }

        .buttons {
            display: flex;
            flex-direction: row;
            column-gap: $gutterSmall;
            align-items: center;
        }
    }
}

@media only screen and (max-width: 1023px) {
    .CalypsoSupportBanner {
        padding: $gutterMedium;
        padding-bottom: 0px;

        > .CalypsoSupportBanner-inner {
            flex-direction: column;

            .buttons {
                align-self: flex-end;
            }
        }
    }
}
