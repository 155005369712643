@import "src/style/variables.scss";

$colorSize: 72px;

.Visage2Composer {
    align-items: flex-start;

    > .ms-Dialog-main {
        margin-top: $gutterXXXLarge;
        padding: 0px;
        overflow: visible;

        .PopupHeader {
            margin: 0px;
        }

        .Visage2ComposerHeader, .composer-section-wrapper, .composer-footer, .SpintrSearch, .Visage2ComposerPeoplePicker-footer {
            margin-left: $gutterXLarge;
            margin-right: $gutterXLarge;
        }

        .Visage2ComposerHeader {
            .ms-Persona-details {
                padding-right: $gutterXSmall;
            }
        }

        .ms-Dropdown {
            .ms-Dropdown-title {
                display: flex;
                line-height: initial;
            }
        }
    }

    .composer-scroll, .user-lists {
        max-height: 50vh;
        overflow-y: scroll;
        overflow-y: overlay;
        padding: 0 $gutterLarge;

        &.user-lists {
            padding-bottom: $gutterLarge;
        }
    }

    .frame-form {
        margin-top: $gutterXLarge;
        height: 240px;
        border-radius: $borderRadius;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        padding: $gutterMedium;
        box-sizing: border-box;
        color: #FFF;
        font-size: 20px;
        position: relative;
        
        .frame-form-inner {
            height: 100%;
            display: flex;
            overflow: auto;
            flex-direction: column;
            justify-content: space-around;
        }
        
        > div {
            width: 100%;

            .public-DraftEditorPlaceholder-root {
                color: #FFF !important;
                font-size: 20px;
            }
        }
        
        .absolute-button {
            position: absolute;
            z-index: 2;
            width: 20px;

            &.remove-button {
                top: $gutterMedium;
                right: $gutterMedium;
            }
    
            &.text {
                bottom: $gutterMedium;
                left: $gutterMedium;
                .Visage2Icon {
                    svg {
                        * {
                            fill: #FFF;
                        }
                    }
                }
            }
        }
    }

    .colors {
        .list {
            list-style: none;
            margin: 0 (-$gutterMedium) 0 0;
            padding: 0;

            .item {
                display: inline-block;
                margin-right: $gutterMedium;
                vertical-align: middle;

                .color-wrapper {
                    // border: 3px solid transparent;
                    display: inline-block;
                    height: $colorSize;
                    width: $colorSize;

                    .color {
                        border: 1px solid #ebdddc;
                        border-radius: 0;
                        cursor: pointer;
                        display: inline-block;
                        height: 100%;
                        position: static;
                        width: 100%;
                    }
                }

                &.active {
                    .color-wrapper {
                        border-color: color("dusk-grey");
                    }
                }
            }
        }
    }

    .fonts {
        .list {
            list-style: none;
            margin: 0;
            padding: 0;
            text-align: center;

            .item {
                margin-bottom: $gutterSmall;

                &:last-child {
                    margin-bottom: 0;
                }

                .sample {
                    color: color("dark-grey");
                    display: inline-block;
                    overflow: hidden;
                    user-select: none;
                    text-overflow: ellipsis;
                    white-space: pre;
                    width: 100%;
                }

                &:hover {
                    opacity: 0.75;
                }
            }
        }
    }

    .composer-form {
        display: flex;
        flex-direction: row;
        font-size: 12px;

        > div {
            &:first-child {
                flex-grow: 1;
                max-width: 100%;
            }
        }
        .effect-button {
            margin-left: $gutterMedium;
        }

        .product-text {
            color: color("neutralBlue");
        }
    }
    .composer-form:has(.effect-button) {
        > div {
            &:first-child {
                max-width: calc(100% - 40px);
            }
        }
    }

    .publish-on-startpage-checkbox {
        margin: $gutterMedium $gutterLarge;
    }

    .composer-footer {
        margin-top: $gutterMedium;
        display: flex;
        justify-content: flex-end;
        column-gap: $gutterSmall;

        .ms-Button {
            height: 48px;
            width: 100%;
            border-radius: $borderRadius;
        }
    }

    .composer-section-wrapper {
        margin-top: $gutterMedium;

        .composer-section-header {
            margin-bottom: calc(#{$gutterTiny} * 3);
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        &.horizontal {
            display: flex;
            flex-direction: row;
            align-content: center;
            column-gap: $gutterXXLarge;

            .composer-section-header {
                margin-bottom: 0px;
            }
        }

        .attach-selector {
            display: flex;
            flex-direction: row;
            flex-flow: wrap;
            column-gap: $gutterMedium;
            row-gap: $gutterMedium;

            .attach-type {
                height: 32px;
                width: 32px;
                border-radius: 32px;
                display: flex;
                align-items: center;
                align-content: center;
                justify-content: center;
                
                &:hover {
                    opacity: 0.75;
                }
            }
        }
    }

    .composer-form {
        margin-top: $gutterLarge;
    }

    .effect-button {
        margin-bottom: $gutterMedium;
        user-select: none;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        [role="button"] {
            background: conic-gradient(from 202.2deg at 50% 50%, #FA6F3A 0deg, #F9C00F 112.23deg, #4FAEF0 224.47deg, #76E46B 285.54deg, #BD91EA 360deg);
            border-radius: $borderRadiusSharper;
            box-sizing: border-box;
            color: #FFFFFF;
            display: inline-block;
            height: 20px;
            line-height: 20px;
            text-align: center;
            width: 20px;

            div.label-component {
                display: inline-block;
                height: 1em;
                line-height: 1em;
                padding: ($gutterXSmall - 1px) 0;
                vertical-align: top;
            }
                
            &:hover {
                opacity: 0.75;
            }
        }
    }

    .attached-content {
        margin-top: $gutterMedium;
        display: flex;
        flex-direction: column;
        row-gap: $gutterMedium;
    }

    &:not(.can-post) {
        .composer-footer {
            .ms-Button {
                background-color: color(visage2LightGray);
                border: 1px solid color(visage2LightGray);
                color: color(mid-grey);
                pointer-events: none;
            }
        }
    }
}

.public-DraftStyleDefault-ltr {
    left: -2px;
}

.type-selector {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    column-gap: $gutterSmall;
    row-gap: $gutterSmall;

    .type-selector-type {
        flex-grow: 1;
        flex-shrink: 1;
        border-radius: $borderRadius;
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: $gutterSmall;
        padding: calc((#{$gutterTiny} * 2)) calc((#{$gutterTiny} * 3));

        .icon-wrapper {
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            height: 28px;
            width: 28px;
            border-radius: 18px;
            background-color: #FFF;

            .Visage2Icon-status-type-7 {
                position: relative;
                top: -2px;
                left: -1px;
            }
        }
        
        &:hover {
            opacity: 0.75;
        }
    }

    &.type-count-4 {
        .type-selector-type {
            flex-basis: 30%;
        }
    }
}

.Visage2ComposerImages {
    position: relative;

    .add {
        margin-top: $gutterMedium;

        > div {
            display: flex;
            flex-direction: row;
            column-gap: $gutterXSmall;
            align-content: center;
        }
    }

    .options {
        border: 1px solid color("smoke");
        border-radius: $borderRadius;
        padding: $gutterMedium;
        position: relative;

        .remove-button {
            position: absolute;
            right: $gutterMedium;
            top: $gutterMedium;
            z-index: 3;
        }

        .option {
            margin-top: $gutterSmall;

            .option-input, .remove-icon {
                display: inline-block;
                vertical-align: middle;
            }

            .option-input {
                border: 1px solid color("smoke");
                border-radius: $borderRadiusSharper;
                width: calc(100% - 36px);

                .ms-TextField-fieldGroup {
                    line-height: 22px;
                    min-height: ($gutterMedium * 2) + 22px;
                }

                input {
                    line-height: 22px;
                    min-height: ($gutterMedium * 2) + 22px;
                    padding: $gutterMedium $gutterSmall;
                }
            }

            .remove-icon {
                border-radius: 50%;
                cursor: pointer;
                font-size: 10px;
                margin-left: $gutterMedium;
                line-height: 20px;
                text-align: center;
                width: 20px;
            }
        }
    }
}

$imageSize: 64px;

.something-fun-confetti {
    .confetti {
        z-index: 2;
        position: absolute;
        width: 100%;
        top: 0px;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        border-top-left-radius: $borderRadius;
        border-top-right-radius: $borderRadius;
        justify-content: center;
        -webkit-animation: loaderFadeIn 1s;
        -moz-animation: loaderFadeIn 1s;
        -ms-animation: loaderFadeIn 1s;
        -o-animation: loaderFadeIn 1s;
        animation: loaderFadeIn 1s;
        pointer-events: none;
    }

    .emojis {
        z-index: 2;
        width: calc(100% + 30px);
        position: absolute;
        bottom: 0px;
        left: -10px;
        display: flex;
        justify-content: space-between;
        pointer-events: none;
        -webkit-animation: loaderFadeIn 1s;
        -moz-animation: loaderFadeIn 1s;
        -ms-animation: loaderFadeIn 1s;
        -o-animation: loaderFadeIn 1s;
        animation: loaderFadeIn 1s;
    }
}

.SocialComposerImages {
    position: relative;

    .images {
        .image {
            position: relative;
        }

        .drop-zone {
            border: 0 none;
            padding: 0;

            input {
                position: absolute;
            }

            &:hover {
                background-color: initial;
            }

            &.is-dragging-file-over {
                background-color: initial;
                border: 0 none;
                margin: 0;
            }
        }

        .upload-placeholder, .image-stage {
            align-items: center;
            background-color: color(visageMidBlueBackground);
            border-radius: $borderRadius;
            cursor: pointer;
            display: flex;
            height: 240px;
            justify-content: center;
            overflow: hidden;
            position: relative;
            width: 100%;
            transition: background-color 0.3s;
            // border: 1px dashed color(neutralBlue);

            &:hover {
                background-color: color(light-grey);
            }
        }

        .upload-placeholder {
            position: relative;

            .remove-button {
                position: absolute;
                right: $gutterMedium;
                top: $gutterMedium;
                z-index: 3;
            }

            .content {
                text-align: center;         

                .Visage2Icon {
                    margin: 0 auto;
                    margin-bottom: $gutterSmall;
                }           
            }

            .label-component {
                margin-bottom: $gutterTiny;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .remove {
            position: absolute;
            top: $gutterMedium;
            right: $gutterMedium;
            z-index: 3;
        }

        .image-stage {
            display: flex;
            gap: $gutterTiny;

            .image {
                max-height: 300px;
                position: relative;
                overflow: hidden;
                height: 100%;
                display: flex;
                align-content: center;
                align-items: center;
                justify-content: center;

                .image-wrapper {
                    max-width: 100%;

                    img {
                        max-height: 300px;
                    }
                }
            }

            &.display1 {
                .image {
                    flex: 100% 1 1;
                    max-width: 100%;
                }
            }

            &.display2 {
                .image {
                    flex: 50% 1 1;
                    max-width: calc(50% - 2px);
                }
            }

            &.display3 {
                flex-wrap: wrap;

                .image {
                    max-height: 150px;
                    flex: 50% 1 1;
                    max-width: calc(50% - 2px);

                    &:nth-child(1) {
                        flex: 100% 1 1;
                        max-width: 100%;
                    }
                }
            }

            &.display4 {
                flex-wrap: wrap;

                .image {
                    max-height: 150px;
                    flex: 50% 1 1;
                    max-width: calc(50% - 2px);
                }
            }

            &.display5 {
                flex-wrap: wrap;

                .image {
                    max-height: 150px;
                    flex: 33% 1 1;
                    max-width: calc(33% - 2px);

                    &:nth-child(1), &:nth-child(2) {
                        flex: 50% 1 1;
                        max-width: calc(50% - 2px);
                    }
                }
            }
        }
    }
}

.bookmark {
    position: relative;
    
    .bookmark-actions {
        position: absolute;
        width: 100%;
        top: 0px;
        padding: $gutterMedium;
        box-sizing: border-box;

        .group {
            &:not(.right) {
                background-color: color(visageGray6);
                border-radius: $borderRadiusSharper;
                display: inline-block;
                height: 22px;
                width: 44px;
            }

            &.right {
                position: absolute;
                top: $gutterMedium;
                right: $gutterMedium;
            }

            .action {
                height: 22px;
                width: 22px;
                display: inline-block;
                text-align: center;
                position: relative;

                i {
                    font-size: 8px;
                    line-height: 22px;
                    position: absolute;
                    height: 22px;
                    width: 22px;
                    left: 0px;
                    top: 0px;
                    color: color(dark-grey);
                }

                &:hover {
                    opacity: 0.5;
                }
            }
        }
    }
}

.video-stage {
    position: relative;

    .thumbnail {
        background-color: #000;
        border-radius: $borderRadius;
        height: 300px;
        overflow: hidden;
        width: 100%;

        img {
            max-width: 100%;
            max-height: 300px;
            margin: 0 auto;
            overflow: hidden;
        }
    }

    .remove-button {
        color: #FFF;
        height: 24px;
        position: absolute;
        right: $gutterXLarge;
        top: $gutterXLarge;
        width: 24px;
    }
}

@media screen and (max-width: 1023px) {
    .SocialComposerImages {
        width: 100%;

        .images .upload-placeholder, .images .image-stage {
            height: 200px;
        }

        .images .image-stage {
            &.display1 .image, &.display2 .image {
                max-height: 200px;
            }

            &.display3 .image, &.display4 .image {
                max-height: 100px;
            }
        }
    }
}

.Visage2ComposerColorSelector-wrapper {
    margin-top: $gutterMedium;
    margin-right: calc(#{$gutterLarge} * -1);
    margin-left: calc(#{$gutterLarge} * -1);

    > .label-component {
        margin-left: $gutterLarge;
    }

    .Visage2ComposerColorSelector {
        overflow-x: scroll;
        white-space: nowrap;
        margin-top: $gutterSmall;
        height: 62px;

        .item {
            border-radius: $borderRadius;
            display: inline-block;
            vertical-align: top;
            margin-right: $gutterSmall;
            height: 52px;
            width: 62px;
            position: relative;

            .item-not-selected,
            .item-selected {
                position: absolute;
                bottom: $gutterXSmall;
                right: $gutterXSmall;
                height: 16px;
                width: 16px;
                border-radius: 8px;
            }

            .item-selected {
                background-color: #51DE79;

                svg {
                    display: block;

                    path {
                        &:first-child {
                            display: none;
                        }
                    }
                }
            }

            .item-not-selected {
                border: 1px solid #FFF;
                height: 14px;
                width: 14px;
            }

            &:first-child {
                margin-left: $gutterLarge;
            }

            &:last-child {
                margin-right: $gutterLarge;
            }
        }
    }
}

.Visage2ComposerPeoplePicker {
    .Visage2ComposerPeoplePicker-footer {
        margin-top: $gutterLarge;
        display: flex;
        justify-content: flex-end;
    }

    .SpintrSearch {
        margin-left: 0px;
        margin-right: 0px;
    }

    .user-list {
        margin-bottom: $gutterXXLarge;

        > .label-component {
            margin-bottom: $gutterXSmall;
        }

        > .unstyled-button {
            display: flex;
            flex-direction: row;
            column-gap: $gutterMedium;
            align-items: center;
            align-content: center;
            margin-bottom: $gutterSmall;
            padding: $gutterSmall;
            border: 1px solid color(borderColor);
            border-radius: $borderRadius;

            &.not-selected {
                svg {
                    path {
                        &:last-child {
                            display: none;
                        }
                    }
                }
            }

            &:not(.selected) {
                &:hover {
                    opacity: 0.75;
                }
            }

            .ms-Persona {
                flex-grow: 1;
            }
        }
    }
}

.SocialComposerTargets {
    display: flex;
    flex-direction: row;
    column-gap: $gutterXSmall;
}

.Visage2ComposerExternalFile {
    border: 1px solid color(borderColor);
    padding: $gutterMedium;
    border-radius: $borderRadius;
    display: flex;
    flex-direction: column;
    row-gap: $gutterXSmall;

    .composer-file-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: $gutterXSmall;

        .label-component, .composer-add-file {
            flex-grow: 1;
        }

        .composer-add-file {
            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: $gutterXSmall;

            &:hover {
                opacity: 0.75;
            }
        }
    }
}

.Visage2ComposerSystemStatus {
    .SpintrDatePicker {
        .react-datepicker-wrapper {
            width: 100%;

            input {
                width: 100%;
            }
        }
    }
}

.status-ball {
    height: 10px;
    width: 10px;
    border-radius: 10px;
}