@import "src/style/variables";

.AcademyCourseListItem {
    width: 100%;
    max-width: 300px;
    padding: $gutterMedium;
    border: 1px solid color(borderColor);
    border-radius: $borderRadiusSharp;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    &.big {
        max-width: 400px;
    }

    &.create {
        display: flex;
        justify-content: center;
        align-items: center;

        border: 1px dashed color(visageMidBlue);
        background-color: color(visageMidBlueBackground);

        .Visage2Icon {
            background-color: #fff;
            padding: $gutterMedium;
            border-radius: $borderRadiusSharp;
            margin-bottom: $gutterMedium;
        }
    }

    .AcademyCourseListItem-info {
        margin-top: $gutterSmall;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;

        .course-description {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: $gutterXSmall;
            min-height: 21px;
        }

        .AcademyCourseCategories {
            margin-top: $gutterXSmall;
            min-height: 22px;
        }
    }

    .AcademyCourseProgress {
        margin-top: $gutterSmall;
    }

    &:hover {
        opacity: 0.75;
    }
}
