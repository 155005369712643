$colors: (
    black: #000,
    dark-grey: #22234A,
    mid-grey: #475267,
    grey: #727E94,
    dusk-grey: #d5d5d5,
    light-grey: #F8F8FF,
    light-blue: #787CDD,
    red: #EB0014,
    // warning: we can't seem to use names that are already defined in CSS such as "red" or "salmon", instead use red
    salmon: #FC5371,
    smoke: #EAEAEA,
    white: #FFF,
    office365: #D83B01,
    green: #1F7F35,
    spintrGreen: #00A578,
    outline: #488dc8,
    yellow: #ADA000,
    orange: #FFAE2C,
    darkOrange: #A86800,
    disabled: #a19f9d,
    visageGray: #091B3D,
    visageGray2: #363853,
    visageGray3: #6D7588,
    visageGray6: #F7F7F9,
    neutralBlue: #2772F0,
    borderColor: #F4F4F5,
    borderColor2: #F7F8FA,
    bookmarkColor: #F8F9FF,
    blueHighlight: #ECE9FE,
    visageMidBlue: #787CDD,
    visageMidBlueBackground: #F1F2FC,
    visage2LightGray: #F4F4F5,
    visage2LightGray2: #ECF0F3,
    spintrWhite: #FFFFFF,
    spintrGrey: #727E94,
    primaryContent: #4E4F6C,
    backgroundColor: #F0F0F0
);

@function color($color) {
    @return map-get($colors, $color);
}

$borderRadiusSharper: 5px;
$borderRadiusSharp: 10px;
$borderRadius: 12px;
$borderRadiusRound: 16px;
$borderRadiusRounder: 30px;

$gutterTiny: 3px;
$gutterXSmall: 6px;
$gutterSmaller: 9px;
$gutterSmall: 12px;
$gutterMedium: 16px;
$gutterLarge: 20px;
$gutterXLarge: 20px;
$gutterXXLarge: 24px;
$gutterXXLargeAlt: 32px;
$gutterXXXLarge: 45px;
$gutterXXXLargeInverted: -45px;

$font: Eloquia, sans-serif;
$secondaryFont: Eloquia, sans-serif;

$rightPanelWidth: 255px;

$spintrSidebarMaxWidth: 340px;
$spintrSidebarMinWidth: 260px;
$spintrSidebarItemHeight: 36px;

$formFooterBarContentPaddingBottom: 76px;

/**
 * Header and footer sizes
 */

$spintrHeaderHeight: 90px;
$spintrResponsiveHeaderHeight: 64px;
$spintrResponsiveFooterHeight: 76px;

/**
 * Circle sizes
 */

$circleSmall: 28px;
$circleMedium: 36px;
$circleLarge: 40px;
$circleXLarge: 54px;

/**
 * Z-Index Table
 */
$spintrSidebarZIndex: 1;
$spintrHeaderZIndex: 36;
$spintrHeaderSearchZIndex: 37;
$spintrOverlayZIndex: 40;
$spintrOverlayContentZIndex: 41;
$spintrComposerOverlay: 50;
$spintrComposerContainer: 51;

/*
Transitions
*/
$bgColorTransition: background-color 250ms;
$fastTransition: 250ms;

/* 
Responsive breakpoints:

Startpage:
 * 1509: Start scaling down social feed
 * 1439: Stop scaling social feed, start scaling information feed
 * 1280: Reduce sidebar width from spintrSidebarMaxWidth to spintrSidebarMinWidth
*/

$vmTabletPortrait: 768px;
$vmPhoneLandscape: 960px;
$vmPhonePortrait: 480px;
$vmTabletLandscape: 1024px;

$responsiveHeaderHeight: 64px;

$socialFeedWidth: 440px;
$socialFeedMinWidth: 390px;

$modulesAreaMaxWidth: 850px;

$rightColumnMaxWidth: 225px;
$rightColumnMinWidth: 225px;

$commandBarHeight: 40px;

$prioritySystemStatusesHeight: 57px;