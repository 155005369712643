@import "src/style/variables.scss";

.SocialPost {

    .pin-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: $gutterXSmall;
        margin-bottom: $gutterMedium;
    }

    .interactive-text {
        a {
            word-break: break-word;
        }

        .product-link {
            color: color("outline");
        }
    }

    .status-frame {
        .stage-wrapper {
            min-height: 200px;
            position: relative;

            .stage {
                align-items: center;
                border-radius: $borderRadius;
                box-sizing: border-box;
                display: flex;
                min-height: 200px;
                justify-content: center;
                padding: 0 $gutterMedium;
                user-select: none;
                width: 100%;

                .text {
                    color: #FFF;
                    text-align: center;
                    overflow: hidden;
                    width: 100%;

                    a {
                        color: #FFF;
                    }
                }
            }
        }
    }
}

.interfactive-product-link-tooltip {
    cursor: default;
}